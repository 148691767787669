import React, {useEffect, useState} from 'react';
import Layout from "../components/layout";
import {post} from "../helper/api";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {navigate} from "gatsby";

const IndexPage = (props) => {
    useEffect(() => {
        const loggedIn = window.sessionStorage.getItem('logged_in');
        if(!loggedIn || loggedIn !== 'true') {
            navigate('/authenticate');
        }
    }, []);

    const [recurring, setRecurring] = useState(false);
    const [formData, setFormData] = useState({
        isRecurring: recurring
    });
    const [error, setError] = useState(null);
    const [paymentLink, setPaymentLink] = useState(null);
    const [inProgress, setInProgress] = useState(false);

    const onUpdate = (value, input) => {
        switch (input) {
            case 'isRecurring':
                setRecurring(value);
                break;
        }
        formData[input] = value;
        setFormData({...formData});
        setError(null);
    }

    const createOrder = async () => {
        if (!formData.name || formData.name.trim() === '') {
            return setError('Name is invalid');
        }
        if (!formData.email || formData.email.trim() === '') {
            return setError('Email is invalid');
        }
        if (!formData.amount || formData.amount <= 0) {
            return setError('Amount is invalid');
        }
        if (formData.isRecurring && (!formData.frequency || formData.frequency.trim() === "")) {
            return setError('Select frequency');
        }
        if (!formData.creator) {
            return setError('Enter your name');
        }
        setInProgress(true);

        const result = await post(`/orders`, formData);
        if (result) {
            if (result.error) {
                setError(result.error)
            } else if (result.id) {
                setPaymentLink(`${window.location.href}checkout/${result.id}`)
            }
        }
        setInProgress(false);
    }

    return (
        <Layout>
            <div className="wrapper w-full lg:w-1/2 pt-8" style={{marginBottom: 20}}>
                {paymentLink
                    ? <div>
                        <h3>Payment link</h3>
                        <div className={"flex items-center mt-6 mb-16"}>
                            <div className={"border-default p-2 w-full"}>{paymentLink}</div>
                            <CopyToClipboard className={"ml-2 cursor-pointer"} text={paymentLink}>
                                <img src={"/images/copy.png"} height={30} width={30} alt={"copy to clipboard"}/>
                            </CopyToClipboard>
                        </div>
                        <div className="form-group my-8 flex items-center justify-center">
                            <button type="button"
                                    onClick={() => setPaymentLink(null)}
                                    className="w-1/2 text-white bg-denim hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg px-5 py-3">
                                Go to new order
                            </button>
                        </div>
                    </div>
                    : <form>
                    <h3>Generate new order</h3>
                    <h5 className={"text-sm text-red mt-5"}>{error}&nbsp;</h5>
                    <div className={"mt-2"}>
                        <div className="form-group mb-6">
                            <input type="text"
                                   onChange={(event) => {
                                       onUpdate(event.target.value, 'name')
                                   }}
                                   className="form-control
                                              w-full
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                   id="nameInput"
                                   placeholder="Enter full name"/>
                        </div>
                        <div className="form-group mb-6">
                            <input type="email"
                                   onChange={(event) => {
                                       onUpdate(event.target.value, 'email')
                                   }}
                                   className="form-control
                                              w-full
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                   id="emailInput"
                                   placeholder="Enter email"/>
                        </div>
                        <div className="form-group mb-6 flex items-center justify-between">
                            <div className="form-group">
                                <span className={"text-2xl mr-4"}>&pound;</span>
                                <input type="number"
                                       onChange={(event) => {
                                           onUpdate(event.target.value, 'amount')
                                       }}
                                       className="form-control
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                       id="amountInput"
                                       placeholder="Amount"/>
                            </div>
                            <div className={"flex items-center"}>
                                <div className={"flex items-center"}>
                                    <input id="recurring" type="checkbox" checked={recurring}
                                           onChange={(event) => {
                                               onUpdate(event.target.checked, 'isRecurring')
                                           }}
                                           className="bg-gray-50 border-gray-300 focus:ring-3 focus:ring-blue-300 h-8 w-8 rounded"/>
                                    <label htmlFor="recurring" className="ml-3 text-gray-900">
                                        Is recurring
                                    </label>
                                </div>
                                <div className={"flex items-center"}>
                                    <label htmlFor="frequency"
                                           className={`ml-8 mr-3 ${recurring ? 'text-gray-900' : 'text-gray-300'}`}>
                                        Frequency:
                                    </label>
                                    <select
                                        className={"border-default focus:ring-blue-500 focus:border-blue-500 w-full px-2 py-1"}
                                        onChange={(event) => {
                                            onUpdate(event.target.value, 'frequency')
                                        }}
                                        disabled={!recurring}>
                                        <option value={""}>Select...</option>
                                        <option value={"monthly"}>monthly</option>
                                        <option value={"quarterly"}>quarterly</option>
                                        <option value={"annually"}>annually</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                        <div className="form-group mb-6">
                            <input type="text"
                                   onChange={(event) => {
                                       onUpdate(event.target.value, 'comment')
                                   }}
                                   className="form-control
                                              w-full
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                   id="commentInput"
                                   placeholder="Add comment..."/>
                        </div>
                    </div>
                    <h4 className={"mt-10"}>Your details:</h4>
                    <div className={"mt-5"}>
                        <div className="form-group mb-6">
                            <input type="text"
                                   onChange={(event) => {
                                       onUpdate(event.target.value, 'creator')
                                   }}
                                   className="form-control
                                              w-full
                                              px-3
                                              py-2
                                              text-gray-700
                                              border-default
                                              rounded
                                              m-0
                                              focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none"
                                   id="myNameInput"
                                   placeholder="Enter your name"/>
                        </div>
                        <div className="form-group my-8 flex items-center justify-center">
                            <button type="button"
                                    onClick={createOrder}
                                    disabled={inProgress}
                                    className="w-1/2 text-white bg-denim hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 rounded-lg px-5 py-3">
                                Generate
                            </button>
                        </div>
                    </div>

                </form>}
            </div>
        </Layout>
    )
}

export default IndexPage
